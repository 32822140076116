// import test from './utils/test';
// import igarashi_san from './utils/igarashi_san';
// import Person from './class/Person';
// import slick from './utils/slick.min';
// import 'matchHeight';
// import 'lity';
// import 'drawsvg';
// import morpheus from './utils/svg-morpheus';
import matchHeight from './utils/matchHeight.min';
import style from './utils/style';
//
// test.foo();
// test.bar();
//
// igarashi_san();
//
// let es6 = new Person('ECMAScript 2015');
// es6.sayHello();
