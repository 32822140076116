$(function () {
	$('a[href^="#"]').click(function () {
		var speed = 500;
		var href = $(this).attr("href");
		var ww = window.innerWidth;
		var target = $(href == "#" || href == "" ? 'html' : href);
		var headerHight = $("header").height();
		var position = target.offset().top;
		var positionSP = target.offset().top-headerHight;
		if (ww < 750) {
			$("html, body").animate({
				scrollTop: positionSP
			}, speed, "swing");
		} else {
			$("html, body").animate({
				scrollTop: position
			}, speed, "swing");
			return false;
		}
	});
});

var widthFlag = '';
$(function() {
		// 画面サイズのチェック
		$(window).on('load resize', function() {
				widthCheck();
		});
});

//画面サイズのチェック
function widthCheck() {
		// 画面幅取得
		var winWidth = $(window).width();

		// 画面幅640以下でフラグがspでない時
		if(winWidth <= 750 && widthFlag != 'sp') {
				// フラグをSPに変更
				widthFlag = 'sp';
				$('.pull-inner').hide();
				$(".sp_pull > h5").on("click", function () {
						$(this).next().slideToggle(600, 'swing');
						$(this).toggleClass('active');
						// $('.slickSlider').slick('setPosition');
						return false;
					});
					$(".navi li a").on("click", function () {
						$(".navi").slideToggle();
						$(".ac_menu").toggleClass("active");
							return false;
						});


		// 画面幅640よりおおきく、フラグがpcでない時
	} else if(winWidth > 750 && widthFlag != 'pc') {
				// フラグをPCに変更
				widthFlag = 'pc';
				$('.pull-inner').show();
		}
}

jQuery(function (e) {
	var thisOffset;
	var thisOffset2;
	var baff = 300;
	var sideCta = $('.sideBtn');
	jQuery(window).on('load resize',function(e){
		thisOffset = $('#ctaStart').offset().top + $('#ctaStart').outerHeight();
		thisOffset2 = $('#ctaClose').offset().top + $('#ctaClose').outerHeight()+baff;
	});

	jQuery(window).scroll(function(e){
		if( $(window).scrollTop() + $(window).height() > thisOffset && $(window).scrollTop() + $(window).height() < thisOffset2){
			sideCta.addClass('fixed');
		} else {
			sideCta.removeClass('fixed');
		}
	});
});

/*/-----------------オープニング*/
// $(function(){
// 	var $window = $(window);
//
// 	if (sessionStorage.getItem('skip')) {
// 		$('.opening-container').css({ 'display' : 'none'});
// 	}
// 	else {
// 		$('.openingContents').animate({opacity : 1});
// 	//自動で消す場合はonclickを外して.delay()で消えるまでの秒数を指定
// 		$('.skip img').on('click', function(e) {
// 			$('.opening-container').fadeOut(400);
// 		});
// 		if (window.sessionStorage) {
// 			sessionStorage.setItem('skip', 'Value');
// 		}
// 	}
// });

$(function(){
	$(".ac_menu").on("click", function () {
		$(this).next().slideToggle();
		$(this).toggleClass("active");
		return false;
	});
});

$(function(){
		$(".has-child > a").on("click", function () {
			var ww = window.innerWidth;
			if (ww < 750) {
			$(this).next().slideToggle();
			$(this).parent().toggleClass("pullOpen");
			return false;
		}
		});
});

// ウィンドウリサイズ処理
$(function(){
	$(window).on('load resize', function(){
		var ww = window.innerWidth,
		header_breakPoint = 750;
		if (ww > header_breakPoint) {
			$('.navi').show();
		} else {
			$('.navi').hide();
		}
	});
});


// フェードイン
$(function(){
    $(window).scroll(function (){
        $('.scrollPalla').each(function(){
					  var baffHeight = 900;
            var elemPos = $(this).offset().top;
            var scroll = $(window).scrollTop();
            var windowHeight = $(window).height();
            if (scroll > elemPos - windowHeight + baffHeight){
                $(this).addClass('active');
            } else {
							$(this).removeClass('active');
						}
        });
    });
});

// フェードイン
$(function(){
    $(window).scroll(function (){
        $('.scrollIn,.scroll').each(function(){
            var elemPos = $(this).offset().top;
            var scroll = $(window).scrollTop();
            var windowHeight = $(window).height();
            if (scroll > elemPos - windowHeight){
                $(this).addClass('active');
            }
        });
    });
			$('.loadIn').each(function(){
				$(this).addClass('active');
			});
});

$(function() {
    var showFlag = false;
    var topBtn = $('#toTop');
    topBtn.css('bottom', '-100px');
    var showFlag = false;
    //スクロールが100に達したらボタン表示
    $(window).scroll(function () {
        if ($(this).scrollTop() > 500) {
            if (showFlag == false) {
                showFlag = true;
                topBtn.stop().animate({'bottom' : '30px'}, 200);
            }
        } else {
            if (showFlag) {
                showFlag = false;
                topBtn.stop().animate({'bottom' : '-100px'}, 200);
            }
        }
    });
    //スクロールしてトップ
    topBtn.click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });
});

$(function() {
	$('.bgBlue').matchHeight();
});
$(function() {
	$('.solutionBox').matchHeight();
});

// var startPos = 0,winScrollTop = 0;
// $(window).on('scroll',function(){
//     winScrollTop = $(this).scrollTop();
//     if (winScrollTop >= startPos) {
//         $('.topContents').addClass('bgDonw');
//     } else {
//         $('.topContents').removeClass('bgDonw');
//     }
//     startPos = winScrollTop;
// });

// $(function() {
// 	$('.slider').slick({
//     autoplay: true,
//     autoplaySpeed: 6000,
// 		speed: 2000,
//     infinite: true,
//     arrows: false,
//     fade: true,
// 		asNavFor: '.catch'
// 	});
// 	$('.catch').slick({
// 		asNavFor: '.slider',
// 		autoplay: true,
// 		autoplaySpeed: 6000,
// 		speed: 2000,
// 		infinite: true,
// 		arrows: false,
// 		fade: true,
// 		dots: false,
// 		focusOnSelect: true
// 	});
// });
//
// $(function() {
// 	$('.contents02__slider ul').slick({
//     autoplay: true,
//     autoplaySpeed: 10000,
// 		speed: 800,
//     infinite: true,
//     arrows: true,
// 		slidesToShow: 1,
// 		slidesToScroll: 1,
// 		centerMode: true,
// 		centerPadding: '15%',
// 		cssEase: 'ease-out',
// 		responsive: [{
// 			breakpoint: 950,
// 			settings: {
// 				arrows: false, // 前後の矢印非表示
// 				centerPadding: '0',
// 				speed: 2000
// 			}
// 		}]
// 	});
// });
// $(function() {
//   $('.bgBlue').matchHeight();
// });
//
// // アコーディオン
// $(function () {
// 	var ww = window.innerWidth;
// 		$(".pullDown > p:first-child").on("click", function () {
// 				$(this).next().slideToggle(600, 'swing');
// 				$(this).toggleClass('active')
// 				return false;
// 			});
// 			if (ww < 767) {
// 				$(".sp_pull > p").on("click", function () {
// 						$(this).next().slideToggle(600, 'swing');
// 						$(this).toggleClass('active')
// 						return false;
// 					});
// 		  } else {
// 		  }
// });
//
// $(function(){
//   $('.thumb li').click(function(){
//     var class_name = $(this).attr("class");
//     var num = class_name.slice(5);
//     $('.main li').hide();
//     $('.item' + num).fadeIn();
//   });
// });
//
//
//タブ
// $(function () {
//   /*初期表示*/
//   $('.tabContent').hide();
//   $('.tabContent').eq(0).show();
//   $('.tabButton > li').eq(0).addClass('active');
//   /*クリックイベント*/
//   $('.tabButton > li').each(function () {
//     $(this).on('click', function () {
//       var index = $('.tabButton > li').index(this);
//       $('.tabButton > li').removeClass('active');
//       $(this).addClass('active');
//       $('.tabContent').hide();
//       $('.tabContent').eq(index).show();
//     });
//   });
// });



// <?php if(is_front_page()) : ?>
// <script type="text/javascript">
// jQuery(function() {
// $(".scroll").click(function(event){
// event.preventDefault();
// var url = $(this).attr("href");
//
// var dest = url.split("#");
// var target = dest[1];
// var target_offset = $("#"+target).offset();
// var target_top = target_offset.top;
//
// $('body,html').animate({scrollTop:target_top}, 800, 'swing');
// return false;
// });
// });
// </script>
// <?php else : ?>
// <script type="text/javascript">
// jQuery(function() {
// $('a[href^=#]').click(function() {
// var speed = 800;
// var href= $(this).attr("href");
// var target = $(href == "#" || href == "" ? 'html' : href);
// var position = target.offset().top;
// $('body,html').animate({scrollTop:position}, speed, 'swing');
// return false;
// });
// });
// </script>
// <?php endif; ?>
// $(function() {
// 	var mySVG = $('#svg1').drawsvg({
//   duration:1000,
//   stagger:100,
//   easing:'swing',
//   reverse:false,
// 	callback: function() {
// 		//処理を記述
// 		$('#svg1').addClass('callback');
// 	}
//   });
//   mySVG.drawsvg('animate');
// });



// window.onload = function(){
// 	let currentIcon = "pdcaP";
// 	let myIcons = new SVGMorpheus('#pdcaIconSet',{
// 		duration: "250",
// 		easing: 'linear',
// 		rotation: "clock"
// 	});
//
// 	$(window).scroll(function () {
// 		var showFlag = false;
// 			if ($(this).scrollTop() > 100) {
// 				if(currentIcon == "pdcaP"){
// 						myIcons.to("pdcaD");
// 						currentIcon = "pdcaD";
// 					}
// 			} else {
// 				myIcons.to("pdcaP");
// 				currentIcon = "pdcaP";
// 			}
// 	});
// };
